import React, { Component } from 'react';

import "../css/header.scss";


export default function Header()
{
    return (
        <>
            <div className="header">
                <div className="header-01"></div>
                <div className="header-02"></div>
                <div className="header-03"></div>
            </div>
            <div className="header-spacer"></div>
        </>
    );
}


export class Header1 extends Component
{
    state = {
    }
    render()
    {
        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/"><img alt="logo" src="/images/resume-play--www--title-01.png" /></a>
                </div>
            </nav>
        )
    }
}