import React from 'react';

import "../css/footer.scss";


export default function Footer()
{    
    return (
        <footer className="row justify-content-md-center">
            <div className="col-12 col-md-auto">
                <a href="/">Lionheart Media Group LLC ©{new Date().getFullYear().toString()}</a>
            </div>
            <div className="col-12 col-md-auto">
                <a href="/privacy/">Privacy Policy</a>
            </div>
        </footer>
    )
}