import React, { Component } from 'react';

import "../css/home.scss";

export default class Home extends Component
{
    state = {
    }
    render()
    {
        var items = [
            {
                title: "Paper Airplane Wars",
                description: "Fight the Paper Airplane Wars. Get the high score. Become a hero!<br />The most addicting game since Flappy Birds.",
                google_id: "com.resumeplay.paperairplanewars",
                amazon_id: "ResumePlay-com-Paper-Airplane-Wars/dp/B08YRT7B3T",
                apple_id: "paper-airplane-wars/id1581866391",
                image_url: "https://www.resumeplay.com/images/paper-airplane-wars--logo-01-512x512.png"
            },
            {
                title: "Block Breaker Infinity",
                google_id: "com.resumeplay.blockbreakerinfinity",
                image_url: "https://www.resumeplay.com/images/block-breaker-infinity-512x512.png"
            },
            {
                title: "Space Debris",
                google_id: "com.resumeplay.spacedebris",
                image_url: "https://www.resumeplay.com/images/space-debris-512x512.png"
            },
            {
                title: "Santa Lost His Sleigh",
                google_id: "com.resumeplay.santalosthissleigh",
                image_url: "https://www.resumeplay.com/images/santa-lost-his-sleigh-512x512.png"
            },
            {
                title: "A-Maze-Thing!",
                google_id: "com.resumeplay.amazething",
                image_url: "https://www.resumeplay.com/images/a-maze-thing-512x512.png"
            },
            {
                title: "Speed Scramble",
                google_id: "com.resumeplay.speedscramble",
                image_url: "https://www.resumeplay.com/images/speed-scramble-512x512.png"
            },
            {
                title: "Pong Mi",
                google_id: "com.resumeplay.pongmi",
                image_url: "https://www.resumeplay.com/images/pong-mi-512x512.png"
            }
        ];


        return (
            <div className="container p-home">

                <h1>Lionheart Media Group LLC</h1>

                <div className="p">
                    Lionheart Media Group LLC is a technology leader focusing on web and mobile solutions. We aim to deliver top user
                    experiences using cutting edge cloud technologies.
                </div>

                <div className="p">
                    Our primary focus is to create mobile experiences through apps and games.
                </div>

                <div className="p">
                    <h2>Our Games</h2>
                    {items.map((item) => (
                        <div key={item.title} className="row" data-game-id={item.google_id}>
                            <div className="col-6 logo">
                                <img alt={item.title} title={item.title} src={item.image_url} />
                            </div>
                            <div className="col-6 title">
                                <h2 alt={item.title} title={item.title}>{item.title}</h2>
                                {item.description &&
                                    <div className="description" dangerouslySetInnerHTML={{ __html: item.description }} />
                                }
                                <div className="mobile-stores">

                                    {item.apple_id &&
                                        <a rel="noopener noreferrer" target="_blank" title={item.title} alt={item.title} href={"https://apps.apple.com/us/app/" + item.apple_id}>
                                            <img title={item.title} alt={item.title} src="https://www.resumeplay.com/images/mobile-stores--apple.png" />
                                        </a>
                                    }
                                    
                                    {item.google_id &&
                                        <a rel="noopener noreferrer" target="_blank" title={item.title} alt={item.title} href={"https://play.google.com/store/apps/details?id=" + item.google_id}>
                                            <img title={item.title} alt={item.title} src="https://www.resumeplay.com/images/mobile-stores--android.png" />
                                        </a>
                                    }

                                    {item.amazon_id &&
                                        <a rel="noopener noreferrer" target="_blank" title={item.title} alt={item.title} href={"https://www.amazon.com/" + item.amazon_id}>
                                            <img title={item.title} alt={item.title} src="https://www.resumeplay.com/images/mobile-stores--amazon.png" />
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div >
        )
    }
}